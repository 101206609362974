import React from 'react';
import styles from './Banner.module.css';
import { Container, Row, Col } from 'react-bootstrap';
import swmcbglogo from '../../assets/images/swmc-bgLogo.png';
const Banner = (props) => {


    return (
        <Container fluid={true} className={styles.banner_container} style={{
            ...(props.styles ? props.styles : '')
        }}>

            <img src={swmcbglogo} style={{ width: 'auto', maxWidth: '274px', position: 'absolute', left: '0', bottom: '0' }} />

            <Row style={{
                marginLeft: '0px', marginRight: '0px', zIndex: '1000'
            }}>
                <Col lg={props.colLg ? props.colLg : 8} sm={12} className={styles.desktopColumn}>
                
                    <Row className={styles.banner_title}>
                        {props.title}
                    </Row>

                    {props.description ?

                        <Row className={styles.description}>
                            {props.description}
                        </Row>

                        : null}

                    {/* <Row className={styles.banner_link}>
                        <Button
                            className={styles.banner_btn}
                            onClick={() => window.open(data.link_url, '_blank')}
                        >
                            <div style={{
                                font: 'normal normal bold 18px/22px Montserrat',
                                letterSpacing: '0px',
                                color: '#1147BA',
                                textTransform: 'uppercase',
                                opacity: '1',
                            }}>
                                {data.link_button_text}
                            </div>
                        </Button>
                    </Row> */}
                </Col>

                <div>

                </div>
            </Row>

        </Container>
    );
}

export default Banner;