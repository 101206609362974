import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga";
import Benefits from "../../components/HomepageComponents/BenefitsSection";
// import Banner from '../../components/HomepageComponents/InnerBanner'
import Banner from "../../components/HomepageComponents/Banner";
import ServicesSection from "../../components/HomepageComponents/ServicesSection";
import { Link } from "react-router-dom";
import styles from "./index.module.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import CustomCard from "../../components/CustomCard";
import { Helmet } from "react-helmet";
import conventional from "../../assets/images/LoanSection/Conventional.svg";
import federal from "../../assets/images/LoanSection/Federal.svg";
import fha_va from "../../assets/images/LoanSection/FHA_VA.svg";
import fha from "../../assets/images/LoanSection/FHA.svg";
import jumbosmil from "../../assets/images/LoanSection/JumbosMil.svg";
import line from "../../assets/images/line.svg";
const data = {
  title: "Loan Programs",
  subTitle: '"Our Goal is to help Banks Prosper in Mortgage Lending"',
  cards: [
    {
      img: conventional,
      heading: "Conventional",
      link: "/images/LoanSection/Conventional.svg",
    },
    {
      img: fha_va,
      heading: "FHA & VA Jumbos",
      link: "/images/LoanSection/FHA_VA.svg",
    },
    {
      img: federal,
      heading: "Federal Housing Administration (FHA)",
      link: "/images/LoanSection/Federal.svg",
    },
    {
      img: fha,
      heading: (
        <>
          FHA-203K Rehab
          <br /> Fixed & ARMs
        </>
      ),
      link: "/images/LoanSection/FHA.svg",
    },
    {
      img: jumbosmil,
      heading: (
        <>
          Jumbos up to <br /> $2.5 Million
        </>
      ),
      link: "/images/LoanSection/JumbosMil.svg",
    },
  ],
};

const {
  loan_section_container,
  title,
  sub_title,
  loan_cards_container,
  loan_card,
  loan_card_image,
  loan_card_body,
  loan_card_title,
  loan_card_sub_title,
  loan_card_actionable,
} = styles;

const Homepage = () => {
  const scroller = () => {
    let x = document.getElementById("benefitSection").offsetTop;
    window.scrollTo({
      top: x,
      left: 100,
      behavior: "smooth",
    });
  };
  const location = useLocation();
  useEffect(() => {
    if (location && location.pathname) {
      ReactGA.pageview(location.pathname); // Send the pageview with the correct path
    }
    window.scrollTo(0, 0);
  }, [location]); // Dependency on location to rerun effect when location changes


  return (
    <article>
      <Helmet>
        <title>Banker's Plus</title>
        <meta property="og:url" content="https://bankers.plus/index" />
        <meta property="og:title" content="Banker's Plus - Home" />
        <meta
          property="og:description"
          content="Residential mortgage loan and financing are provided by Banker's Plus"
        />
        <meta
          property="og:keyword"
          content="Mortgage Lending, see my loan status"
        />
        <meta property="og:brand" content="Banker's Plus" />
      </Helmet>

      <div>
        <Banner scroll={scroller} />

        <Container className={styles.loan_section_container}>
          <div className={styles.loan_title}>{data.title}</div>

          <Row className={styles.loan_cards_container}>
            <Col
              className={`d-flex justify-content-center align-items-center flex-wrap`}
            >
              <CustomCard
                genericClassnameObj={{
                  card: loan_card,
                  cardImage: loan_card_image,
                  cardBody: loan_card_body,
                  title: loan_card_title,
                  subTitle: loan_card_sub_title,
                  actionable: loan_card_actionable,
                }}
                cardStyles={{
                  padding: "0",
                }}
                contentStyles={{
                  textAlign: "center",
                }}
                content={{
                  img: data.cards[0].img,
                  heading: data.cards[0].heading,
                  description: data.cards[0].description,
                  actionableItem: () => false,
                }}
              />

              <Image className={styles.line} src={line} />

              <CustomCard
                genericClassnameObj={{
                  card: loan_card,
                  cardImage: loan_card_image,
                  cardBody: loan_card_body,
                  title: loan_card_title,
                  subTitle: loan_card_sub_title,
                  actionable: loan_card_actionable,
                }}
                cardStyles={{
                  padding: "0",
                }}
                contentStyles={{
                  textAlign: "center",
                }}
                content={{
                  img: data.cards[1].img,
                  heading: data.cards[1].heading,
                  description: data.cards[1].description,
                  actionableItem: () => false,
                }}
              />
              <Image className={styles.line} src={line} />
              <CustomCard
                genericClassnameObj={{
                  card: loan_card,
                  cardImage: loan_card_image,
                  cardBody: loan_card_body,
                  title: loan_card_title,
                  subTitle: loan_card_sub_title,
                  actionable: loan_card_actionable,
                }}
                cardStyles={{
                  padding: "0",
                }}
                contentStyles={{
                  textAlign: "center",
                }}
                content={{
                  img: data.cards[2].img,
                  heading: data.cards[2].heading,
                  description: data.cards[2].description,
                  actionableItem: () => false,
                }}
              />
              <Image className={styles.line} src={line} />
              <CustomCard
                genericClassnameObj={{
                  card: loan_card,
                  cardImage: loan_card_image,
                  cardBody: loan_card_body,
                  title: loan_card_title,
                  subTitle: loan_card_sub_title,
                  actionable: loan_card_actionable,
                }}
                cardStyles={{
                  padding: "0",
                }}
                contentStyles={{
                  textAlign: "center",
                }}
                content={{
                  img: data.cards[3].img,
                  heading: data.cards[3].heading,
                  description: data.cards[3].description,
                  actionableItem: () => false,
                }}
              />
              <Image className={styles.line} src={line} />
              <CustomCard
                genericClassnameObj={{
                  card: loan_card,
                  cardImage: loan_card_image,
                  cardBody: loan_card_body,
                  title: loan_card_title,
                  subTitle: loan_card_sub_title,
                  actionable: loan_card_actionable,
                }}
                cardStyles={{
                  padding: "0",
                }}
                contentStyles={{
                  textAlign: "center",
                }}
                content={{
                  img: data.cards[4].img,
                  heading: data.cards[4].heading,
                  description: data.cards[4].description,
                  actionableItem: () => false,
                }}
              />
            </Col>
          </Row>

          <Row className={`${styles.loanprogram_viewmore} m-0`}>
            <Link to="/loan-programs" className={styles.viewMoreLink}>
              Click here to View More
            </Link>
          </Row>
        </Container>
        <div id="benefitSection">
          <Benefits />
        </div>
      </div>
    </article>
  );
};

export default Homepage;
