import React from "react";
import Form from "react-bootstrap/Form";
import Classes from "./index.css";

const Dropdown = (props) => {
  let options;
  if (props.values.length > 0) {
    options = props.values.map((ele) => {
      if (typeof ele == "object") {
        if (ele.name == "Please select a state") {
          return (
            <option value=" " key={ele.name}>
              {ele.name}
            </option>
          );
        }
        return (
          <option value={ele.name} key={ele.name}>
            {ele.name}
          </option>
        );
      } else {
        return (
          <option value={ele} key={ele}>
            {ele}
          </option>
        );
      }
    });
  }
  return (
    <div style={{ marginTop: "5px" }}>
      <Form.Control
        as="select"
        value={props.defaultValue}
        className={ Classes.dropdown}
        name={props.name}
        {...props.register}
        placeholder={props.placeholder}
        onChange={(ele) => props.Change(ele)}
        style={{ boxShadow: "none", outline: "none" }}
        {...props}
      >
        {options}
      </Form.Control>
      {/* {props.register ? props.errors[props.name] && <p>{props.errors[props.name].message}</p> : null} */}
      <p> {props.errors[props.name]?.message}</p>
    </div>
  );
};
export default Dropdown;
 