import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga";
import {
  Container,
  Row,
  Col,
  Image,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import styles from "./index.module.css";
import { Helmet } from "react-helmet";
import banner from "../../assets/images/about-image.png";
const AboutUs = () => {
  const location = useLocation();  

  useEffect(() => {
    if (location && location.pathname) {
      ReactGA.pageview(location.pathname);  
    }
    window.scrollTo(0, 0);
  }, [location]);  
  
  const getSunWestSuccessYears = () => {
    // Sun West founded in 08/12/1980 in PST
    const foundedDate = new Date("1980-08-12T00:00:00-07:00"); // PST offset is -07:00
    const todayDate = new Date();

    // Adjust todayDate to PST timezone
    const todayPST = new Date(
      todayDate.toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
    );

    const yearDiff = todayPST.getFullYear() - foundedDate.getFullYear();
    const isDate2BeforeBirthday =
      todayPST.getMonth() < foundedDate.getMonth() ||
      (todayPST.getMonth() === foundedDate.getMonth() &&
        todayPST.getDate() < foundedDate.getDate());

    return isDate2BeforeBirthday ? yearDiff - 1 : yearDiff;
  };

  return (
    <article className="d-flex justify-content-center align-items-center m-0 p-0">
      <Helmet>
        <title>About Us</title>
        <meta property="og:url" content="https://bankers.plus/index" />
        <meta property="og:title" content="Banker's Plus - About Us" />
        <meta
          property="og:description"
          content="Residential mortgage loan and financing are provided by Banker's Plus"
        />
        <meta
          property="og:keyword"
          content="Mortgage Lending, see my loan status"
        />
        <meta property="og:brand" content="Banker's Plus" />
      </Helmet>

      <Container
        fluid
        className={styles.container_mobile}
        style={{ maxWidth: "1600px" }}
      >
        <div className={styles.pageContainer}>
          <Row className="mx-0 px-0 w-100 mt-5">
            <Col
              lg={6}
              md={12}
              className="d-flex justify-content-center align-items-start mt-lg-5"
            >
              <Image
                className={`${styles.aboutus_banner} mt-lg-5`}
                fluid
                src={banner}
              />
            </Col>
            <Col lg={6} md={12} className="m-0 p-0 mt-lg-5">
              <div className={styles.headline_title}>About Us</div>
              <div className={styles.aboutus_wrapper}>
                {/* <div className={styles.about_section_title} style={{ paddingLeft: '0px', paddingRight: '0px' }}>About Us </div> */}
                <div
                  className={styles.about_section_subtitle}
                  style={{ paddingLeft: "0px", paddingRight: "0px" }}
                >
                  Our goal is to help community banks stay Active & Viable in
                  mortgage lending
                </div>
                <div className={styles.about_text}>
                  <p>
                    At Sun West Mortgage Company, Inc. we dedicate ourselves to
                    offering an amazing experience to our customers. To
                    accomplish this, we empower our loan officers so that they
                    can find great rates and provide the most fitting loan
                    options for each customer – at amazing speed. Our focus on
                    technology has given us an edge in the mortgage industry to
                    offer exceptional turn times so the customers can get into
                    the home of their dreams sooner!
                  </p>
                  <p>
                    We are committed to our core values of people, experience,
                    technology, and product. Sun West was founded in 1980 with
                    the perspective of “customers first” and the desire to make
                    the mortgage process easy and stress-free for prospective
                    homeowners. Since then, Sun West has been servicing a
                    multi-billion-dollar loan portfolio and is licensed in 48
                    states, the District of Columbia, Puerto Rico, and the US
                    Virgin Islands. Our {getSunWestSuccessYears()} years of experience has been passed
                    down to everyone here at Sun West through excellent
                    leadership and capabilities.
                  </p>
                  <p>
                    Sun West makes it possible for Banks to offer a wide range
                    of QM & Non-QM loan products: Conventional, HomeStyle®
                    Renovation, HomeStyle® Energy, FHA, FHA-203K, VA, USDA-RD,
                    Reverse Mortgages, Jumbo, HUD-184 Native American, Bank
                    Statement Loans, and more.
                  </p>
                  <p>HomeStyle is a registered trademark of Fannie Mae.</p>
                </div>
                <div className={`${styles.mobile_bg} m-0 p-0`}>
                  <div className={styles.banks_choose}>
                    <p>
                      Banks can choose from four channels of loan delivery to
                      Sun West:
                    </p>
                  </div>

                  <Row className={`${styles.about_section_actionables}`}>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id={styles.tooltip}>
                          Bank originates & Sun West does the rest
                        </Tooltip>
                      }
                    >
                      <div className={styles.about_section_redirect_btn}>
                        <span>Banker's Plus</span>
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger
                      overlay={
                        <Tooltip id={styles.tooltip}>
                          Loans for Bank Employees
                        </Tooltip>
                      }
                    >
                      <div
                        className={styles.about_section_redirect_btn}
                        onClick={() => {}}
                      >
                        <span>Banker's Advantage</span>
                      </div>
                    </OverlayTrigger>
                    <div
                      className={styles.about_section_redirect_btn}
                      onClick={() => {}}
                    >
                      Wholesale
                    </div>

                    <div
                      className={styles.about_section_redirect_btn}
                      onClick={() => {}}
                    >
                      Correspondent
                    </div>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </article>
  );
};

export default AboutUs;
